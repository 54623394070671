
import { Vue, Component } from "vue-property-decorator";
import user from "@/store/modules/UserModule";

@Component
export default class DashboardBase extends Vue {
  async created() {
    let pushToDefaultRoute = true;
    if (user.user != null) {
      if (!user.hasVerifiedEmail) {
        this.$swal.fire({
          titleText: this.$t("MODAL.VERIFICATION_MESSAGE.TITLE").toString(),
          text: this.$t("MODAL.VERIFICATION_MESSAGE.SUB_TITLE").toString(),
          confirmButtonText: "Ok",
          icon: "info"
        });
      } else if (user.subscriptionDetails.expired) {
        this.$swal.fire({
          titleText: this.$t("MODAL.SUBSCRIPTION_EXPIRED.TITLE").toString(),
          text: this.$t("MODAL.SUBSCRIPTION_EXPIRED.SUB_TITLE").toString(),
          confirmButtonText: "Ok",
          icon: "info"
        });
        pushToDefaultRoute = false;
        this.pushRoute("/dashboard/subscription");
      }
    }

    const subscriptionResult = this.$route.query.subscriptionresult;
    if (subscriptionResult && subscriptionResult.length > 0) {
      if (subscriptionResult == "SUCCESS") {
        this.$swal.fire({
          titleText: this.$t(
            "MODAL.SUBSCRIPTION_CONFIRMATION.TITLE"
          ).toString(),
          text: this.$t("MODAL.SUBSCRIPTION_CONFIRMATION.SUCCESS").toString(),
          confirmButtonText: "Ok",
          icon: "success"
        });

        this.pushRoute("/dashboard/subscription");
        pushToDefaultRoute = false;
      }
    }

    if (pushToDefaultRoute) {
      this.pushRoute("/dashboard/quotation-requests");
    }
  }

  pushRoute(targetRoute: string) {
    if (targetRoute != this.$route.fullPath) {
      this.$router.push(targetRoute);
    }
  }
}
